import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const userId = window.localStorage.getItem('user_id');
    const accessToken = window.localStorage.getItem('access_token');

    useEffect(() => {
        if (!userId && !accessToken) {
            navigate('login', { replace: true });
        }
    }, [userId && accessToken, navigate]);

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
