import { PROJECT_LEVEL_ROLE } from 'store/constant';
import dashboard from './dashboard';
import dashboardAdmin from './dashboard-admin';
// import widget from './widget';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';

// ===========================|| MENU ITEMS ||=========================== //

const userType = window.localStorage.getItem('dv_user_type');
const menuItems = {
    items: [userType == PROJECT_LEVEL_ROLE.admin ? dashboardAdmin : dashboard]
};

export default menuItems;
