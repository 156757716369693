import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';
import { ROLE_SPECIALIST } from 'store/constant';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
    const userRole = useSelector((state) => state.userRole);
    // let menuArray = userRole.role === ROLE_SPECIALIST ? menuItem.items.slice(0, 1) : menuItem.items.slice(1, 2);
    let menuArray = menuItem.items;
    const navItems = menuArray.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
