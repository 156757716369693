// action - state management
import * as actionTypes from './actions';
import { ROLE_ADMIN, ROLE_OWNER, ROLE_SPECIALIST } from './constant';

const initialState = {
    role: ROLE_SPECIALIST,
    isSuperAdmin: false
};

// ===========================|| SNACKBAR REDUCER ||=========================== //

const userRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_TYPE_ADMIN:
            console.log('SETTING USER TYPE');
            return {
                role: ROLE_ADMIN,
                isSuperAdmin: false
            };
        case actionTypes.USER_TYPE_SPECIALIST:
            console.log('SETTING USER TYPE');
            return {
                role: ROLE_SPECIALIST,
                isSuperAdmin: false
            };
        case actionTypes.USER_TYPE_OWNER:
            console.log('SETTING USER TYPE');
            return {
                role: ROLE_OWNER,
                isSuperAdmin: true
            };
        default:
            return state;
    }
};

export default userRoleReducer;
