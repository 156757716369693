// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const costCalcAmountInputLimit = 999999;
export const hubspotDummyURL = 'https://app.hubspot.com/contacts/xxxxxx/record/0-3/xxxxxx/';
export const hubspotURLPrefix = 'https://app.hubspot.com/contacts/';
export const maxSupportedPDFSize = 10 * 1024 * 1024; //10 MB
// user roles
export const ROLE_ADMIN = 'admin';
export const ROLE_SPECIALIST = 'specialist';
export const ROLE_OWNER = 'super_admin';

//project level roles
export const PROJECT_LEVEL_ROLE = {
    producer: 'Producer',
    curator: 'Curator',
    projectOwner: 'Owner',
    ownerFreelancer: 'Business Owner/Freelancer',
    specialist: 'Specialist',
    admin: 'DV Admin'
};
