import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconDashboard,
    IconAffiliate,
    IconUsers,
    IconFileAnalytics,
    IconFileDownload,
    IconReportAnalytics,
    IconFileInvoice
} from '@tabler/icons';

// constant
const icons = { IconDashboard, IconAffiliate, IconUsers, IconFileAnalytics, IconFileDownload, IconReportAnalytics, IconFileInvoice };
// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="My Profile" />,
            type: 'item',
            url: '/profile/profile-dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: true
        },
        {
            id: 'network',
            title: <FormattedMessage id="Network" />,
            type: 'item',
            url: '/profile/profile-network/network',
            icon: icons.IconAffiliate,
            breadcrumbs: true
        },
        {
            id: 'my-projects',
            title: <FormattedMessage id="My Projects" />,
            type: 'item',
            url: '/profile/profile-projects/my-projects',
            icon: icons.IconFileAnalytics,
            breadcrumbs: true
            // children: [
            //     {
            //         id: 'file_digitalvillage',
            //         title: <FormattedMessage id="Digital village" />,
            //         type: 'item',
            //         url: 'user-profile'
            //     },
            //     {
            //         id: 'file_cred',
            //         title: <FormattedMessage id="Cred" />,
            //         type: 'item',
            //         url: 'user-profile'
            //     },
            //     {
            //         id: 'file_freshops',
            //         title: <FormattedMessage id="Freshops" />,
            //         type: 'item',
            //         url: 'user-profile'
            //     }
            // ]
        },
        {
            id: 'shared-projects',
            title: <FormattedMessage id="Shared Projects" />,
            type: 'item',
            url: '/profile/profile-projects/shared-projects',
            icon: icons.IconFileDownload,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
